<template>
    <BaseFileUpload
        v-model="avatarFile"
        v-model:uploading="avatarIsUploading"
        :force-show-remove-button="!!formData.avatar"
        :input-attributes="{
            accept: 'image/png, image/jpeg, image/webp'
        }"
        @start-upload="handleAvatarUpload"
    >
        <template #upload="{ openFileDialog }">
            <BaseButton
                class="base-button--outline base-button--outline-dark"
                icon-left="edit"
                :loading="avatarIsUploading"
                @click="openFileDialog"
            >
                Change Avatar
            </BaseButton>
        </template>
    </BaseFileUpload>
</template>

<script setup>
import UpdateUserAvatarMutation from '~/graphql/mutations/updateUserAvatar.graphql';

const avatarFile = ref(undefined);
const avatarPath = ref(undefined);
const avatarUrl = ref(null);
const avatarIsUploading = ref(false);

const formData = ref({
    avatar: null
});

const { execute } = useAsyncMutation('change-avatar');

watch(avatarFile, () => {
    if (formData.value.avatar && !avatarFile.value) {
        avatarPath.value = null;
        avatarUrl.value = null;
        formData.value.avatar = null;
    }
});

async function handleAvatarUpload() {
    avatarIsUploading.value = true;

    try {
        const { data } = await execute({
            mutation: UpdateUserAvatarMutation,
            variables: {
                file: avatarFile.value[0]
            }
        });

        if (data.value?.data?.updateUserAvatar) {
            useAuthStore().user = data.value.data.updateUserAvatar;
        }

        avatarIsUploading.value = false;
    } catch (e) {
        console.error(e);
        avatarIsUploading.value = false;
        avatarFile.value = null;
    }
}
</script>
