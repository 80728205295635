<template>
    <div
        :class="{
            'dn-checkbox--disabled': disabled
        }"
        class="dn-checkbox"
    >
        <label>
            <input
                type="checkbox"
                v-bind="attrs"
                @input="toggleCheck"
            >

            <span class="dn-checkbox__custom">
                <slot
                    name="custom"
                    :is-checked="isSelected"
                />
            </span>

            <span
                v-if="!!$slots.default"
                class="dn-checkbox__label"
            >
                <slot :is-checked="isSelected" />
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'DnCheckbox',

    props: {
        modelValue: {
            type: [String, Boolean, Object, Number, Array],
            default: undefined
        },

        id: {
            type: String,
            default: undefined
        },

        name: {
            type: [String, Number],
            default: undefined
        },

        value: {
            type: [String, Boolean, Object, Number],
            default: undefined
        },

        required: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        trueValue: {
            type: Boolean,
            default: true
        },

        falseValue: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue'],

    computed: {
        attrs() {
            const attrs = {
                id: this.id,
                name: this.name,
                disabled: this.disabled,
                required: this.required
            };

            if (this.value) {
                if (this.value === null || typeof this.value !== 'object') {
                    attrs.value = (this.value === null || this.value === undefined) ? '' : String(this.value);
                }
            }

            return attrs;
        },

        isSelected() {
            if (this.isModelArray) {
                return this.modelValue.includes(this.value);
            }

            if (this.hasValue) {
                return this.modelValue === this.value;
            }

            return this.modelValue === this.trueValue;
        },

        isModelArray() {
            return Array.isArray(this.modelValue);
        },

        hasValue() {
            return this.value;
        }
    },

    methods: {
        removeItemFromModel(newModel) {
            const index = newModel.indexOf(this.value);

            if (index !== -1) {
                newModel.splice(index, 1);
            }
        },

        handleArrayCheckbox() {
            const newModel = [...this.modelValue];

            if (!this.isSelected) {
                newModel.push(this.value);
            } else {
                this.removeItemFromModel(newModel);
            }

            this.$emit('update:modelValue', newModel);
        },

        handleSingleSelectCheckbox() {
            this.$emit('update:modelValue', this.isSelected ? null : this.value);
        },

        handleSimpleCheckbox() {
            this.$emit('update:modelValue', this.isSelected ? this.falseValue : this.trueValue);
        },

        toggleCheck() {
            if (this.disabled) {
                return;
            }

            if (this.isModelArray) {
                return this.handleArrayCheckbox();
            }

            if (this.hasValue) {
                return this.handleSingleSelectCheckbox();
            }

            return this.handleSimpleCheckbox();
        }
    }
};
</script>

<style lang="less" src="./checkbox.less" />
