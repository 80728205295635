<template>
    <BottomModal
        :is-visible="modalStore.activeModal === MODAL_CONTENT_REQUEST"
        class="content-request-modal"
        title="Content request"
        description="Request content that you would like to see on the MedPeers platform"
        @close="modalStore?.closeModal"
    >
        <form
            novalidate
            @submit.prevent="onSubmit"
        >
            <BaseFormField
                required
                :errors="errors.topic ? [errors.topic] : []"
            >
                <template #label>
                    <label for="topic">I would like to see..</label>
                </template>
                <BaseTextarea
                    id="topic"
                    v-model.lazy="topic"
                    type="text"
                    name="topic"
                    rows="2"
                    required
                />
            </BaseFormField>

            <BaseButton
                :loading="loading"
                class="base-button--wide"
            >
                Request content
            </BaseButton>

            <BaseButton
                class="base-button--wide base-button--content-only"
                @click="modalStore.closeModal"
            >
                Cancel
            </BaseButton>
        </form>
    </BottomModal>
</template>

<script setup>
import { useModalStore, MODAL_CONTENT_REQUEST } from '~/store/modal';
import { useToastStore } from '~/store/toast';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import CreateContentRequestMutation from '~/graphql/mutations/createContentRequest.graphql';

const modalStore = useModalStore();
const toastStore = useToastStore();
const { push } = useTagmanager();

const { execute } = useAsyncMutation('create-content-request');
const loading = ref(false);

const validationSchema = object({
    topic: string()
        .required('Topic is required')
});

const { errors, setErrors, defineField, handleSubmit } = useForm({
    validationSchema
});

const [topic] = defineField('topic');

const onSubmit = handleSubmit(async(values) => {
    loading.value = true;
    const { data } = await execute({
        mutation: CreateContentRequestMutation,
        variables: {
            text: values.topic
        }
    });
    loading.value = false;

    if (data.value?.data?.createContentRequest?.id) {
        push({
            event: 'content_request',
            content: values.topic
        });
        modalStore.closeModal();
        toastStore.add('Thank you for your content request', 5000, 'check');
    } else {
        setErrors({
            topic: 'An error occurred while submitting your request. Please try again.'
        });
    }
});
</script>

<style lang="less">
.content-request-modal {
    .dn-form-field,
    .dn-form-field__errors {
        text-align: left;
    }
}
</style>
