<template>
    <div class="search-filters">
        <div class="search-filters__container">
            <BaseTag
                :element="`button`"
                type="button"
                :class="{
                    'base-tag--light': route.query[props.filterQueryParam]
                }"
                style="z-index: 2;"
                @click="setFilter(null)"
            >
                All
            </BaseTag>

            <BaseTag
                v-for="filter in specialisationOptions"
                :key="filter.value"
                :element="`button`"
                type="button"
                :class="{
                    'base-tag--light': route.query[props.filterQueryParam] !== filter.value
                }"
                @click="setFilter(filter.value)"
            >
                {{ filter.label }}
            </BaseTag>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import SpecialisationFiltersQuery from '~/graphql/queries/SpecialisationFilters.graphql';

const router = useRouter();
const route = useRoute();
const { push } = useTagmanager();

const props = defineProps({
    filterQueryParam: {
        type: String,
        required: true
    },
});

const { data: specialisationsResponse } = await useAsyncQuery({
    query: SpecialisationFiltersQuery
});

const specialisationOptions = computed(() => {
    return specialisationsResponse?.value?.specialisationFilters ?? [];
});

// Add the filter value to the URL query params.
const setFilter = (value) => {
    push({
        event: 'search_filters',
        'filter_name': value
    });
    value = route.query[props.filterQueryParam] === value ? null : value;
    router.push({ query: { ...route.query, [props.filterQueryParam]: value || undefined } });
};
</script>

<style src="./SearchFilters.less" lang="less" />
