<template>
    <DelayHydration>
        <div class="skip-links">
            <BaseButton
                class="skip-links__link"
                element="a"
                href="#main-content-landmark"
            >
                Jump to main content
            </BaseButton>
            <BaseButton
                class="skip-links__link"
                element="a"
                href="#footer-landmark"
            >
                Jump to footer
            </BaseButton>
        </div>
    </DelayHydration>
</template>

<style lang="less" src="./SkipLinks.less"></style>
