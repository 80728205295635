<template>
    <input
        v-show="false"
        ref="fileInput"
        name="file"
        type="file"
        v-bind="inputAttributes"
        @change="uploadFile"
    >
    <slot
        name="upload"
        :open-file-dialog="openFileDialog"
    >
        <BaseButton
            :loading="uploading"
            @click="openFileDialog"
        >
            Upload a file
        </BaseButton>
    </slot>

    <!-- <template v-if="uploading">
        <BaseButton
            v-if="allowCancel"
            type="button"
            @click="cancelUpload"
        >
            <slot name="cancelUploadLabel">Cancel upload</slot>
        </BaseButton>
    </template> -->
</template>

<script setup>
const props = defineProps({
    modelValue: {
        type: Object,
        required: false,
        default: null
    },
    allowCancel: {
        type: Boolean,
        required: false,
        default: false
    },
    uploading: {
        type: Boolean,
        required: false,
        default: false
    },
    inputAttributes: {
        type: Object,
        required: false,
        default: null
    },
    fileKey: {
        type: String,
        required: false,
        default: 'file'
    },
    forceShowRemoveButton: {
        type: Boolean,
        required: false,
        default: false
    }
});

const emit = defineEmits(['cancelUpload', 'update:uploading', 'input', 'update:modelValue', 'update:uploading', 'startUpload']);

const fileInput = ref(null);

function openFileDialog() {
    if (!props.uploading && !props.file) {
        fileInput.value.click();
    }
}

// function cancelUpload() {
//     emit('cancelUpload');
//     emit('update:uploading', false);
//     clearFile();
// }

// function clearFile() {
//     fileInput.value.value = '';
//     // For IE11 and some other browsers
//     fileInput.value.type = '';
//     fileInput.value.type = 'file';

//     emit('input', null);
// }

/**
 * Actually upload the file
 */
function uploadFile(e) {
    const files = e.target.files || e.dataTransfer.files;

    if (!files.length) {
        return;
    }

    emit('update:modelValue', files);
    emit('update:uploading', true);

    /**
     * Build a suitable multipart form-data request
     */
    const formData = new FormData();
    formData.append(props.fileKey, files[0]);

    // Allow custom handling of uploads using this "startUpload"-event
    emit('startUpload', formData);
}
</script>
