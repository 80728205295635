<template>
    <BaseModal class="menu-modal">
        <slot />
    </BaseModal>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
const modalStore = useModalStore();
const route = useRoute();

// watch route and close modal if route changes
watch(() => route.path, () => {
    modalStore.closeModal();
});
</script>

<style lang="less">
.dn-modal.menu-modal {
    top: var(--navbar-height);

    .dn-modal__backdrop {
        background: linear-gradient(180deg, #114A4E 0%, rgba(17, 74, 78, 0) 100%);
        transition: opacity 2s 0ms cubic-bezier(0.2, 0, 0, 1);
    }

    .dn-modal__wrapper {
        display: grid;
        padding: .625rem;
        
        @media @q-md-min {
            padding-right: var(--spacing-md);
        }
    }

    .dn-modal__dialog {
        --max-width: 23rem;
        justify-self: end;
        align-self: start;
        border-radius: var(--borderRadius-lg);
        transition: transform .5s 0ms cubic-bezier(0.2, 0, 0, 1);
    }

    .dn-modal__close {
        top: var(--spacing-sm);
        right: var(--spacing-sm);
    }
}
</style>
