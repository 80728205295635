<template>
    <BottomModal
        :is-visible="modalStore.activeModal === MODAL_LOGIN"
        class="login-modal"
        :title="title"
        :description="description"
        @close="modalStore?.closeModal"
    >
        <LoginStep1
            v-if="currentStep === STEP_1"
        />

        <LoginStep2
            v-if="currentStep === STEP_2"
        />
    </BottomModal>
</template>

<script setup>
import { useModalStore, MODAL_LOGIN } from '~/store/modal';
import { useAuthStore } from '~/store/auth';
import LoginStep1 from '~/components/Authentication/LoginStep1.vue';
import LoginStep2 from '~/components/Authentication/LoginStep2.vue';

const modalStore = useModalStore();
const authStore = useAuthStore();

const STEP_1 = 1;
const STEP_2 = 2;

const currentStep = computed(() => {
    if (authStore.tempEmail && authStore.verificationSent) {
        return STEP_2;
    }

    return STEP_1;
});

const title = computed(() =>{
    switch (currentStep.value) {
    case STEP_1:
        return 'Login';
    case STEP_2:
        return 'Making sure it\'s you';
    }
});

const description = computed(() => {
    switch (currentStep.value) {
    case STEP_1:
        return 'Enter your e-mail address and we\'ll send you a verification code';
    case STEP_2:
        return 'Code sent to ' + authStore.tempEmail;
    }
});
</script>

<style lang="less">
.login-modal {
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: var(--spacing-xs);
    }

    .login-step-1 .dn-form-field {
        text-align: left;
    }

    .dn-form-field__errors {
        text-align: left;
    }
}
</style>
