<template>
    <ClientOnly>
        <VTooltip
            :triggers="['click', 'focus']"
            :distance="10"
            :auto-hide="true"
            class="info-icon"
        >
            <BaseIcon
                icon="info-outline"
                aria-label="More information"
            />
    
            <template #popper>
                <slot />
            </template>
        </VTooltip>
    </ClientOnly>
</template>

<style lang="less">
.info-icon {
    height: 1rem;
    cursor: pointer;
    display: inline-block;
}
</style>
