<template>
    <slot :items="result?.hits || []" />
</template>

<script setup>
const route = useRoute();

const { result, search } = useAlgoliaSearch('videos_index');

const facetFilters = computed(() => {
    const filters = [];

    if (route.query.specialisation) {
        filters.push(`specialisations.id:${route.query.specialisation}`);
    }

    return filters;
});

async function executeSearch() {
    await search({
        query: route.query.q || '',
        requestOptions: {
            facetFilters: facetFilters.value
        }
    });
}

await useAsyncData('ssr-search-results', () => executeSearch());

watch(() => route.query, async() => {
    return executeSearch();
});
</script>