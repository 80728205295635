<template>
    <BaseButton 
        icon-left="burger"
        class="base-button--icon-only base-button--outline hamburger-menu-button"
        :class="{ 'base-button--active': modalStore.activeModal === MODAL_HAMBURGER }"
        @click="modalStore.toggleModal(MODAL_HAMBURGER)"
    />
    <HamburgerMenuModal />
</template>

<script setup>
import { useModalStore, MODAL_HAMBURGER } from '~/store/modal';

const modalStore = useModalStore();
</script>

<style lang="less" scoped>
// button {
//     .button-clean();
//     height: 100%;
// }

.user-avatar {
    height: 100%;
}
</style>