<template>
    <MenuModal 
        :is-visible="modalStore.activeModal === MODAL_HAMBURGER"
        class="hamburger-menu"
        @close="modalStore.closeModal"
    >
        <ul class="hamburger-menu__main-links">
            <li>
                <BaseButton :element="defineNuxtLink({})" to="/upload/checklist" icon-left="plus">Upload video</BaseButton>
            </li>
            <li>
                <NuxtLink to="/">Overview</NuxtLink>
            </li>
            <li>
                <NuxtLink to="/account">Account & profile</NuxtLink>
            </li>
            <li>
                <NuxtLink to="/favorites">Your favorites</NuxtLink>
            </li>
            <li>
                <NuxtLink to="/uploads">Your video uploads</NuxtLink>
            </li>
        </ul>
        <ul class="hamburger-menu__secondary-links">
            <li>
                <NuxtLink to="/how-to-make-a-video">How to make a video</NuxtLink>
            </li>
            <li>
                <NuxtLink to="/about-us">Contact MedPeers</NuxtLink>
            </li>
            <li>
                <NuxtLink to="/faq">FAQ</NuxtLink>
            </li>
            <li>
                <NuxtLink 
                    to="https://tikkie.me/pay/IOSolutions/fjaSc1tiKJRyFfXhQd2Ses/10,50,100"
                    gtm-donate
                    external
                >
                    Donate
                </NuxtLink>
            </li>
        </ul>
        <hr>
        <ul class="hamburger-menu__footer-links">
            <li>
                <NuxtLink to="/legal">Terms and conditions</NuxtLink>
            </li>
            <li>
                <NuxtLink to="/legal/privacy">Privacy</NuxtLink>
            </li>
            <li>
                <NuxtLink to="/legal/security">Security</NuxtLink>
            </li>
        </ul>
    </MenuModal>
</template>

<script setup>
import { useModalStore, MODAL_HAMBURGER } from '~/store/modal';

const modalStore = useModalStore();
</script>

<style lang="less">
.hamburger-menu {
    hr {
        position: relative;
        background: var(--color-secondary);
        opacity: .2;
        height: 1px;
        border: none;
        margin-top: var(--spacing-md);
        margin-bottom: var(--spacing-md);
        margin-left: calc(var(--spacing-md) * -1);
        margin-right: calc(var(--spacing-md) * -1);
    }

    .router-link-exact-active {
        text-decoration: underline;
    }
}

.hamburger-menu__main-links {
    .typography-body();
    .list-clean();
    display: grid;
    justify-content: start;
    gap: var(--spacing-sm);
    line-height: 1;
    color: var(--color-secondary);
}

.hamburger-menu__secondary-links {
    .typography-small();
    .list-clean();
    margin-top: var(--spacing-xl);
    display: grid;
    gap: var(--spacing-sm);
    line-height: 1;
    color: var(--color-darkest);
}

.hamburger-menu__footer-links {
    .list-clean();
    display: flex;
    gap: var(--spacing-md);
    line-height: 1;
    font-size: .75rem;
}
</style>