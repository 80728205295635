<template>
    <button
        class="profile-menu-button"
        :class="{ 'base-button--active': modalStore.activeModal === MODAL_PROFILE }"
        @click="modalStore.toggleModal(MODAL_PROFILE)"
    >
        <BaseAvatar :use-user-avatar="true" />
    </button>
    <ProfileMenuModal />
</template>

<script setup>
import { useModalStore, MODAL_PROFILE } from '~/store/modal';

const modalStore = useModalStore();
</script>

<style lang="less">
button.profile-menu-button {
    .button-clean();
    height: 2rem;
    border-radius: 50%;

    &.base-button--active {
        box-shadow: 0 0 0 2px var(--color-lightest);
    }

    .base-avatar {
        height: 100%;
    }
}
</style>
