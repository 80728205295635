<template>
    <DelayHydration>
        <footer
            id="footer-landmark"
            class="footer layout__footer"
        >
            <h3 class="footer__tagline">Made for healthcare professionals</h3>
            <ul class="footer__main-links">
                <li>
                    <NuxtLink to="/faq">FAQ</NuxtLink>
                </li>
                <li>
                    <NuxtLink to="/legal">Legal information</NuxtLink>
                </li>
                <li>
                    <NuxtLink to="/about-us">Contact MedPeers</NuxtLink>
                </li>
                <li>
                    <NuxtLink to="/about-us">About MedPeers</NuxtLink>
                </li>
            </ul>

            <ul class="footer__social-links">
                <li>
                    <a
                        href="https://www.linkedin.com/company/medpeers"
                        target="_blank"
                        rel="noopener"
                    >
                        <span class="sr-only">Follow us on LinkedIn</span>
                        <BaseIcon icon="linkedin" />
                    </a>
                </li>
                <!-- <li>
                    <a href="/" target="_blank">
                        <BaseIcon icon="facebook" />
                    </a>
                </li> -->
                <li>
                    <a
                        href="https://instagram.com/medpeers_?igshid=YTQwZjQ0NmI0OA=="
                        target="_blank"
                        rel="noopener"
                    >
                        <span class="sr-only">Follow us on Instagram</span>
                        <BaseIcon icon="instagram" />
                    </a>
                </li>
                <!-- <li>
                    <a href="/" target="_blank">
                        <BaseIcon icon="x" />
                    </a>
                </li> -->
            </ul>

            <ul
                class="footer__secondary-links"
            >
                <li>
                    <NuxtLink to="/legal">Terms & conditions</NuxtLink>
                </li>
                <li>
                    <NuxtLink to="/legal/privacy">Privacy</NuxtLink>
                </li>
                <li>
                    <NuxtLink to="/legal/security">Security</NuxtLink>
                </li>
            </ul>

            <div class="footer__bottom">
                <hr>
                <p>
                    © {{ currentYear }} MedPeers
                </p>
                <p>
                    <a
                        href="https://www.digitalnatives.nl?utm_source=medpeers&utm_campaign=backlink&utm_medium=referral"
                        target="_blank"
                    >Made by Digital Natives</a>
                </p>
            </div>
        </footer>
    </DelayHydration>
</template>

<script setup>
const currentYear = new Date().getFullYear();
</script>

<style lang="less" src="./TheFooter.less" />
