<template>
    <div class="default-layout">
        <SkipLinks />
        <NuxtPwaManifest />
        <TheNavBar :class="`navigation-bar--${activeNavigationState.name}`">
            <template #left>
                <BaseButton
                    v-if="activeNavigationState.showBackButton"
                    class="back-button base-button--content-only base-button--icon-only"
                    icon-left="arrow-left"
                    @click="goBack"
                />
                <NavLogo v-if="activeNavigationState.showLogo" />
            </template>
            <template #right>
                <BaseButton
                    v-if="activeNavigationState.showSearchButton"
                    icon-left="search"
                    class="search-button base-button--icon-only base-button--content-only"
                    @click="modalStore.toggleModal(MODAL_SEARCH)"
                />
                <BaseButton
                    v-if="activeNavigationState.showMainCta"
                    class="main-cta base-button--outline"
                    icon-right="plus"
                    :element="defineNuxtLink({})"
                    to="/upload/checklist"
                    gtm-location="menu"
                >
                    Upload video
                </BaseButton>
                <ProfileMenuButton v-if="activeNavigationState.showProfileAvatar" />
                <HamburgerMenuButton v-if="activeNavigationState.showHamburger" />
                <BaseButton
                    v-if="activeNavigationState.showLoginButton"
                    icon-right="account"
                    gtm-join
                    gtm-location="menu"
                    @click="modalStore.toggleModal(MODAL_LOGIN)"
                >
                    Log in
                </BaseButton>
                <BaseButton
                    v-if="activeNavigationState.showNeedHelp"
                    class="base-button--outline"
                    :element="defineNuxtLink({})"
                    to="/how-to-make-a-video"
                >
                    Need help?
                </BaseButton>
            </template>
        </TheNavBar>
        <slot />
        <MicroFooter v-if="authStore.loggedIn" />
        <TheFooter v-else />

        <SearchModal />
        <LoginModal />
        <RegisterModal />
        <AccessRequestedModal />
        <ContentRequestModal />
        <TheToasts />
        <!-- <TheLoadingCurtain /> -->

        <!-- <TheCookieBar /> -->
    </div>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';
import { useModalStore, MODAL_LOGIN, MODAL_SEARCH } from '~/store/modal';
import { useAuthStore } from '~/store/auth';

const { push } = useTagmanager();
const navigationStore = useNavigationStore();
const modalStore = useModalStore();
const authStore = useAuthStore();

useHead({
    meta: [
        {
            name: 'theme-color',
            content: '#072022'
        }
    ],
    htmlAttrs: {
        lang: 'en'
    },
    bodyAttrs: {
        'menu-open': navigationStore.isMainMenuOpen,
        'loading-active': navigationStore.isLoadingStateActive
    },
    script: [
        {
            src: 'https://www.bugherd.com/sidebarv2.js?apikey=mrtax5eh8ciy97etrub7aq',
            async: 'true'
        }
    ]
});

const router = useRouter();

router.beforeEach(() => {
    navigationStore.enableLoadingState();
});

router.afterEach(() => {
    navigationStore.disableLoadingState();
});

const uploadflowRoutes = ['upload-checklist', 'upload-preview', 'upload-video'];
const videoRoutes = ['videos-slug'];

const navigationStateLoggedOut = ref({
    name: 'logged-out',
    showLogo: true,
    showLoginButton: true
});

const navigationStateLoggedIn = ref({
    name: 'logged-in',
    showLogo: true,
    showSearchButton: true,
    showProfileAvatar: true,
    showHamburger: true,
    showMainCta: true
});

const navigationStateFlow = ref({
    name: 'flow',
    showBackButton: true,
    showNeedHelp: true
});

const navigationStateVideopage = ref({
    name: 'videopage',
    showLogo: true,
    showBackButton: true,
    showSearchButton: true,
    showProfileAvatar: true,
    showHamburger: true,
    showMainCta: true
});

const activeNavigationState = computed(() => {
    if (!authStore.loggedIn) {
        return navigationStateLoggedOut.value;
    } else if (uploadflowRoutes.includes(router.currentRoute.value.name)) {
        return navigationStateFlow.value;
    } else if (videoRoutes.includes(router.currentRoute.value.name)) {
        return navigationStateVideopage.value;
    } else {
        return navigationStateLoggedIn.value;
    }
});

function goBack() {
    const routerHistory = router.options.history.state;

    // When the user navigates back to the same page, redirect to the home page
    if (routerHistory.back === routerHistory.current) {
        router.push('/');
        return;
    }
    // When the user has no back history, redirect to the home page
    routerHistory.back ? router.back() : router.push('/');
}

watchEffect(() => {
    if (authStore?.loggedIn) {
        push({
            'logged_in': true
        });
    } else {
        push({
            'logged_in': false
        });
    }
});
</script>

<style lang="less">
.default-layout {
    display: grid;
    min-height: var(--100vh, 100vh);
    grid-template-rows: auto minmax(calc(100vh - var(--navbar-height) - 5rem), 1fr) auto;
    grid-template-columns: 100%;
    grid-auto-flow: column;

    > * {
        grid-column: 1;
    }
}

.base-button.back-button {
    color: var(--color-lightest);

    + .nav-logo {
        position: absolute;
        height: unset;
        left: 50%;
        transform: translateX(-50%);
    }
}

.base-button.main-cta {
    @media @q-md-max {
        display: none;
    }
}

.base-button.search-button {
    color: var(--color-lightest);
    
    @media @q-md-max {
        margin-right: -5px;
    }
}
</style>