<template>
    <TransitionGroup
        name="list"
        tag="ul"
        class="the-toasts"
    >
        <li
            v-for="(toast, index) in toastStore.activeToasts"
            :key="`toast-${index}`"
            role="alert"
            class="the-toasts__toast"
            @click="toastStore.remove(toast.id)"
        >
            {{ toast.message }}
            <BaseIcon v-if="toast.icon" :icon="toast.icon" />
            <button
                class="sr-only"
                @click.stop="toastStore.remove(toast.id)"
            >
                Hide this message
            </button>
        </li>
    </TransitionGroup>
</template>

<script setup>
import { useToastStore } from '~/store/toast';
const toastStore = useToastStore();
</script>

<style lang="less" scoped>
.list-enter-active,
.list-leave-active {
    transition: all 200ms ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;

    @media @q-md-max {
        transform: translateY(-30px);
    }

    @media @q-md-min {
        transform: translateY(30px);
    }
}

.the-toasts {
    .list-clean();
    position: fixed;
    top: 15px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 500;
    display: flex;
    justify-content: center;
    flex-direction: column;
    pointer-events: none;

    @media @q-md-max {
        padding-left: 1rem;
        padding-right: 1rem;
        gap: .5rem;
    }

    @media @q-md-min {
        gap: 1rem;
    }
}

li.the-toasts__toast {
    .typography-body-lead();
    display: grid;
    grid-template-columns: 1fr auto;
    gap: .3125rem;
    align-items: start;
    padding: .3125rem .625rem;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--color-lightest);
    border-radius: var(--borderRadius-md);
    color: var(--color-darkest);
    text-align: center;
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, .4), 0 0 5px 0 rgba(17, 74, 78, .4);
    cursor: pointer;
    pointer-events: all;

    .base-icon {
        font-size: 1.5em;
    }
}
</style>
