<template>
    <BaseModal
        :is-visible="modalStore.activeModal === MODAL_SEARCH"
        class="search-modal"
        transition-name="search"
        :auto-focus="false"
        :hide-default-close-button="true"
        @close="closeSearchModal"
    >
        <div class="search-navigation-bar">
            <div class="search-navigation-bar__container">
                <NavLogo class="search-navigation-bar__logo" />

                <SearchBar />

                <button
                    class="dn-modal__close search-navigation-bar__close-button"
                    aria-label="close"
                    @click="closeSearchModal"
                >
                    <BaseIcon icon="cross" />
                </button>
            </div>

            <SearchFilters
                class="search-navigation-bar__filters"
                :filter-query-param="SPECIALIZATION_QUERY"
            />
        </div>

        <SearchVideosQuery v-slot="{ items }">
            <VideoCardList
                :videos="items"
                class="search-modal__video-card-list"
            >
                <template #noVideos>
                    <div class="search-modal__no-results">
                        <template v-if="route.query[SEARCH_QUERY]">
                            No results for
                            <div class="search-modal__no-results-prompt">"{{ route.query[SEARCH_QUERY] }}"</div>
                        </template>

                        <template v-else>
                            No videos found.
                        </template>
                    </div>
                </template>

                <template #cta>
                    <BentoFrame
                        data-span-all
                        dark
                        class="content-width search-modal__cta"
                    >
                        <p>Place a content request for this topic in the community</p>
    
                        <BaseButton
                            @click="modalStore.toggleModal(MODAL_CONTENT_REQUEST)"
                        >
                            Place content request
                        </BaseButton>
                    </BentoFrame>
                </template>
            </VideoCardList>
        </SearchVideosQuery>

        <MicroFooter class="search-footer" />
    </BaseModal>
</template>

<script setup>
import { useModalStore, MODAL_SEARCH, MODAL_CONTENT_REQUEST } from '~/store/modal';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

const SPECIALIZATION_QUERY = 'specialisation';
const SEARCH_QUERY = 'q';

const modalStore = useModalStore();
const router = useRouter();
const route = useRoute();

const openSearchModal = () => {
    if (modalStore?.activeModal !== MODAL_SEARCH) {
        modalStore?.toggleModal(MODAL_SEARCH);
    }
};

// Close the search modal and remove the search query parameters from the URL.
const closeSearchModal = () => {
    modalStore.closeModal();

    if (route.query[SEARCH_QUERY] || route.query[SPECIALIZATION_QUERY]) {
        router.push({ query: {} });
    }
};

// Open the search modal if the search query is present in the URL.
// Usefull when search modal needs to be opened on page load.
onMounted(() => {
    if (route.query[SEARCH_QUERY] || route.query[SPECIALIZATION_QUERY]) {
        openSearchModal();
    }
});

// Watch for changes in search query and open the search modal if the query is present.
// Usefull when user searches in different component than the search modal.
watch(() => route.query[SEARCH_QUERY], (value) => {
    if (value) {
        openSearchModal();
    } 
});

// Watch for changes in specialization query and open the search modal if the query is present.
// Usefull when user filters in different component than the search modal.
watch(() => route.query[SPECIALIZATION_QUERY], (value) => {
    if (value) {
        openSearchModal();
    } 
});

// Focus the search input when the search modal is opened.
onUpdated(() => {
    if (modalStore.activeModal === MODAL_SEARCH) {
        const searchInput = document.querySelector('input.search-bar__input');
        searchInput?.focus();
    }
});
</script>

<style src="./SearchModal.less" lang="less" />
