<template>
    <DelayHydration>
        <footer
            id="footer-landmark"
            class="micro-footer layout__footer"
            v-bind="$attrs"
        >
            <p>
                © {{ currentYear }} MedPeers
            </p>
            <p>
                <a
                    href="https://www.digitalnatives.nl?utm_source=medpeers&utm_campaign=backlink&utm_medium=referral"
                    target="_blank"
                >Made by Digital Natives</a>
            </p>
        </footer>
    </DelayHydration>
</template>

<script setup>
const currentYear = new Date().getFullYear();
</script>

<style lang="less" src="./MicroFooter.less" />
