<template>
    <div class="login-step-1">
        <form
            novalidate
            @submit.prevent="onSubmit"
        >
            <BaseFormField
                required
                :errors="errors.email ? [errors.email] : []"
            >
                <template #label>
                    <label for="email">Your work email</label>
                </template>
                <BaseInput
                    id="email"
                    v-model.lazy="email"
                    type="email"
                    name="email"
                    autocomplete="email"
                    required
                />
            </BaseFormField>

            <BaseButton
                :loading="loading"
                class="base-button--wide"
            >
                Send verification code
            </BaseButton>
        </form>

        <BaseButton
            class="base-button--content-only base-button--wide"
            @click="modalStore.toggleModal(MODAL_REGISTER)"
        >
            Create account
        </BaseButton>
    </div>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { useModalStore, MODAL_REGISTER } from '~/store/modal';
import { useForm, configure } from 'vee-validate';
import { object, string } from 'yup';
import LoginStep1Mutation from '~/graphql/mutations/auth/loginStep1.graphql';

const { execute } = useAsyncMutation('login');
const authStore = useAuthStore();
const modalStore = useModalStore();
const { push } = useTagmanager();

const loading = ref(false);

configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: false,
    validateOnModelUpdate: false,
});

const validationSchema = object({
    email: string()
        .required('Email address is required')
        .email('This is not a valid email address')
});

const { errors, setErrors, defineField, handleSubmit } = useForm({
    validationSchema
});

const [email] = defineField('email');

const onSubmit = handleSubmit(async(values) => {
    loading.value = true;
    const { data, error } = await execute({
        mutation: LoginStep1Mutation,
        variables: {
            email: values.email
        }
    });
    loading.value = false;

    if (
        error.value ||
        data.value?.data?.loginStep1?.status === 'error'
    ) {
        if (data.value?.data?.loginStep1?.message) {
            setErrors({
                email: data.value.data.loginStep1.message
            });
        } else {
            setErrors({
                email: 'An error occurred'
            });
        }
        return;
    }

    if (data.value?.data?.loginStep1?.status === 'ok') {
        if (email) {
            authStore.verificationSent = true;
            authStore.tempEmail = email.value;

            push({
                event: 'login_step_verification_code'
            });
        }
    }
});

onMounted(() => {
    push({
        event: 'login_step_email'
    });
});
</script>
