<template>
    <div class="login-step-2">
        <form
            novalidate
            @submit.prevent="onSubmit"
        >
            <BaseFormField
                required
                :errors="errors.token ? [errors.token] : []"
                class="token-input"
                @click="focusTokenInput"
            >
                <template #label>
                    <label for="token">Enter code</label>
                </template>
                <div class="token-input__boxes">
                    <input
                        id="token"
                        ref="tokenInput"
                        v-model="token"
                        class="token-input__input"
                        inputmode="numeric"
                        name="token"
                        autocomplete="off"
                        required
                    >

                    <div
                        v-for="i in 4"
                        :key="i"
                        class="token-input__box"
                        :class="tokenAsString.length === i - 1 ? 'token-input__box--focus' : ''"
                    >
                        {{ tokenAsString[i - 1] }}
                    </div>
                </div>
            </BaseFormField>

            <BaseButton
                :loading="loadingVerify"
                class="base-button--wide"
            >
                Verify
            </BaseButton>
        </form>

        <BaseButton
            class="base-button--content-only base-button--wide"
            :class="{ 'base-button--disabled': resendTimer > 0 }"
            :loading="loadingResend"
            :disabled="resendTimer > 0"
            gtm-send-code-again
            @click="resendVerificationCode"
        >
            <template v-if="resendTimer > 0">
                Send again in {{ resendTimer }} seconds
            </template>
            <template v-else>
                Resend code
            </template>
        </BaseButton>
    </div>
</template>

<script setup>
import { useForm, configure } from 'vee-validate';
import { object, string } from 'yup';
import { useAuthStore } from '~/store/auth';
import { useModalStore } from '~/store/modal';
import LoginStep1Mutation from '~/graphql/mutations/auth/loginStep1.graphql';
import LoginStep2Mutation from '~/graphql/mutations/auth/loginStep2.graphql';

const { execute } = useAsyncMutation('login');
const authStore = useAuthStore();
const modalStore = useModalStore();
const { push } = useTagmanager();

const loadingVerify = ref(false);
const loadingResend = ref(false);
const resendTimer = ref(0);

const tokenInput = ref(null);

configure({
    validateOnBlur: true,
    validateOnChange: true,
    validateOnInput: false,
    validateOnModelUpdate: false,
});

const validationSchema = object({
    token: string().required('Token is required')
});

const { errors, setErrors, defineField, handleSubmit } = useForm({
    validationSchema
});

const [token] = defineField('token');

const tokenAsString = computed(() => token.value?.toString().split('') ?? '');
const focusTokenInput = () => tokenInput.value?.focus();

watchEffect(() => {
    token.value = token.value?.replace(/[^0-9]/g, '').slice(0, 4);

    if (token.value?.toString().length === 4) {
        onSubmit();
    }
});

const onSubmit = handleSubmit(async(values) => {
    loadingVerify.value = true;
    const { data, error } = await execute({
        mutation: LoginStep2Mutation,
        variables: {
            email: authStore.tempEmail,
            token: values.token
        }
    });

    loadingVerify.value = false;

    if (
        error.value ||
        data.value?.data?.loginStep2?.status === 'error'
    ) {
        if (data.value?.data?.loginStep2?.message) {
            setErrors({ token: data.value?.data?.loginStep2?.message });
        } else {
            setErrors({ token: 'An error occurred' });
        }
        return;
    }

    if (
        data.value?.data?.loginStep2?.status === 'ok' &&
        data.value?.data?.loginStep2?.user
    ) {
        refreshCookie('XSRF-TOKEN');

        modalStore.closeModal();

        if (modalStore.previousModal === MODAL_LOGIN) {
            push({
                event: 'login_success'
            });
        } else if (modalStore.previousModal === MODAL_REGISTER) {
            push({
                event: 'register_success'
            });
        }

        reloadNuxtApp();
    }
});

const resendVerificationCode = async() => {
    loadingResend.value = true;
    const { data, error } = await execute({
        mutation: LoginStep1Mutation,
        variables: {
            email: authStore.tempEmail
        }
    });

    loadingResend.value = false;

    if (
        error.value ||
        data.value?.data?.loginStep1?.status === 'error'
    ) {
        if (data.value?.data?.loginStep1?.message) {
            setErrors({ token: data.value?.data?.loginStep1?.message });
        } else {
            setErrors({ token: 'An error occurred' });
        }
        return;
    }

    if (data.value?.data?.loginStep1?.status === 'ok') {
        startResendTimer();
    }
};

const startResendTimer = () => {
    resendTimer.value = 30;
    const interval = setInterval(() => {
        if (resendTimer.value === 0) {
            clearInterval(interval);
            return;
        }

        resendTimer.value--;
    }, 1000);
};
</script>

<style lang="less">
.token-input {
    position: relative;
    cursor: text;

    .token-input__input {
        position: absolute;
        left: 0;
        top: 0;

        // thar be dragons - Hide the real input but make sure iOS still sees it for automatic keyboard zooming
        appearance: none;
        border: none;
        background: rgba(0, 0, 0, 0);
        caret-color: transparent;
        color: transparent;

        width: 100%;
        height: 100%;
        z-index: 2;

        &:focus {
            outline: none;
        }
    
        &:focus ~ .token-input__box--focus {
            .input--focussed();
            border-color: var(--color-darkest);

            &:before {
                content: '|';
                font-size: 1em;
                position: absolute;
                top: 50%;
                left: 40%;
                transform: translate(-50%, -50%);
                color: var(--color-darkest);
                animation: blink 1s infinite;
            }
        }
    }
    
    .token-input__boxes {
        display: flex;
        justify-content: center;
        gap: var(--spacing-xs);
    }
    
    .token-input__box {
        .input();
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    
        width: 42px;
        height: 58px;
        border: 1px solid var(--color-muted-gray);
        border-radius: var(--borderRadius-sm);
    }
}

@keyframes blink {
    0%, 49.9%, 100% {
        opacity: 1;
    }

    50%, 99.9% {
        opacity: 0;
    }
}
</style>
