export const useNavigationStore = defineStore('navigation-store', {
    state: () => {
        return {
            mainMenuOpen: false,
            loadingStateActive: false
        };
    },

    getters: {
        isMainMenuOpen() {
            return this.mainMenuOpen;
        },
        isLoadingStateActive() {
            return this.loadingStateActive;
        }
    },

    actions: {
        // Menu overlay
        toggleMainMenu() {
            this.mainMenuOpen = !this.mainMenuOpen;
        },

        openMainMenu() {
            this.mainMenuOpen = true;
        },

        closeMainMenu() {
            this.mainMenuOpen = false;
        },

        // Global loading state (curtain)
        toggleLoadingState() {
            this.loadingStateActive = !this.loadingStateActive;
        },

        enableLoadingState() {
            this.loadingStateActive = true;
        },

        disableLoadingState() {
            this.loadingStateActive = false;
        }
    }
});
