<template>
    <BottomModal
        :is-visible="modalStore.activeModal === MODAL_ACCESS_REQUESTED"
        class="access-requested-modal"
        title="Access requested"
        @close="modalStore?.closeModal"
    >
        <template #description>
            We need to verify your status as a medical professional. 
            <br><br>
            The MedPeers team will be in contact with you soon. Keep an eye on your e-mail!
        </template>

        <BaseButton
            class="base-button--wide"
            @click="modalStore?.closeModal"
        >
            Close
        </BaseButton>
    </BottomModal>
</template>

<script setup>
import { useModalStore, MODAL_ACCESS_REQUESTED } from '~/store/modal';

const modalStore = useModalStore();
</script>

<style lang="less">
</style>
