<template>
    <div
        v-if="banner && !seenCookie"
        class="banner"
    >
        <div class="banner__left">
            <p class="banner__text">{{ banner.text }}</p>

            <BaseButton
                v-if="banner.url"
                :element="defineNuxtLink({})"
                :to="banner.url"
                class="banner__link"
                icon-right="call-made"
            >
                {{ banner.label }}
            </BaseButton>
        </div>

        <button
            class="banner__close"
            @click="onClose"
        >
            <BaseIcon icon="cross" />
        </button>
    </div>
</template>

<script setup>
import LatestBannerQuery from '~/graphql/queries/LatestBanner.graphql';

const { data } = await useAsyncQuery({
    query: LatestBannerQuery
});

const banner = computed(() => data.value?.latest_banner);

const bannersDismissed = useCookie(
    'bannersDismissed',
    {
        default() { return []; },
        maxAge: 60 * 60 * 24 * 365,
    }
);

const seenCookie = computed(() => bannersDismissed.value?.includes(banner.value?.id));

const onClose = () => {
    bannersDismissed.value.push(banner.value?.id);
};
</script>

<style scoped lang="less">
.banner {
    display: flex;
    background-color: var(--color-primary);
    gap: .9375rem;

    padding: 0.625rem var(--spacing-xs);

    @media @q-md-min {
        padding: 0.625rem var(--spacing-md);
    }

    p {
        color: var(--color-darkest);
        margin: 0;
        font-family: var(--font-family-secondary);
        line-height: 1.25;
    }

    .banner__link {
        white-space: nowrap;
        border: 1px solid var(--color-secondary);
    }

    .banner__left {
        flex: 1;
        display: flex;
        gap: .9375rem;

        @media @q-md-min {
            align-items: center;
            justify-content: space-between;
        }

        @media @q-md-max {
            flex-direction: column;
        }
    }

    .banner__close {
        .button-clean();

        color: var(--color-secondary);
        font-size: 1.5rem;
    }
}
</style>
