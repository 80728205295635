<template>
    <form
        class="search-bar"
        @submit.prevent
    >
        <BaseInput
            ref="searchInputRef"
            v-model="searchQuery"
            class="search-bar__input"
            :clearable="true"
            @clear="searchQuery = null"
        >
            <template #clear>
                Clear
            </template>
            <template #after>
                <BaseIcon icon="search" />
            </template>
        </BaseInput>
        <SearchPlaceholder v-if="!searchQuery" />
    </form>
</template>

<script setup>
import { debounce } from 'lodash-es';

const props = defineProps({
    placeholderText: {
        type: String,
        default: 'Search'
    },
    searchQueryParam: {
        type: String,
        default: 'q'
    },
});

// Define the emit function to let the parent component know the search query has changed.
const emit = defineEmits(['onSearch']);

// Define the router and route.
const route = useRoute();
const router = useRouter();

// Local search state of the search bar.
const searchQuery = ref('');

const updateSearchQuery = (value) => {
    // Push the search query param to the URL, based on the passed prop.
    router.push({
        query: {
            ...route.query,
            [props.searchQueryParam]: value || undefined
        }
    });

    // Emit the event to the parent component, so it can refetch the results based on the pushed query param.
    emit('onSearch', value);
};

watch(searchQuery, debounce((value) => {
    updateSearchQuery(value);
}, 400));

onMounted(() => {
    // Set the search value to the query param from the URL.
    searchQuery.value = route.query[props.searchQueryParam] || '';
});
</script>

<style lang="less" src="./SearchBar.less" />
