<template>
    <MenuModal
        :is-visible="modalStore?.activeModal === MODAL_PROFILE"
        class="profile-menu-modal"
        @close="modalStore.closeModal"
    >
        <BaseAvatar 
            avatar-fallback="/images/avatar-placeholder-blue.png"
            :image="authStore.user?.avatar?.conversions?.webp_256"
        />
        <h2>{{ authStore.user.full_name }}</h2>
        <p>
            <template v-if="authStore.user.job_title">
                {{ authStore.user.job_title }}
            </template>
            <template v-if="authStore.user.job_title && authStore.user.institute"> at </template>
            <template v-if="authStore.user.institute">
                {{ authStore.user.institute }}
            </template>
        </p>

        <ChangeAvatar />

        <BaseButton :element="defineNuxtLink({})" to="/account" class="base-button--outline base-button--outline-dark" icon-left="edit">Edit your account</BaseButton>
        <BaseButton class="base-button--content-only" icon-left="logout" gtm-logout @click="authStore.logout()">Log out</BaseButton>
    </MenuModal>
</template>

<script setup>
import { useModalStore, MODAL_PROFILE } from '~/store/modal';
import { useAuthStore } from '~/store/auth';

const modalStore = useModalStore();
const authStore = useAuthStore();
</script>

<style lang="less">
.profile-menu-modal .dn-modal__main {
    display: flex;
    flex-direction: column;
    align-items: center;

    .base-avatar {
        width: 7.5rem;
        margin-bottom: var(--spacing-sm);
    }

    h2 {
        margin-bottom: var(--spacing-2xs);
        color: var(--color-secondary);
    }

    p {
        .typography-label();
        text-align: center;
        margin-top: 0;
        margin-bottom: var(--spacing-xl);
        color: var(--color-secondary);
    }

    .base-button + .base-button {
        margin-top: var(--spacing-sm);
    }
}
</style>
