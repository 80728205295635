<template>
    <NuxtLink
        class="nav-logo"
        to="/"
        exact-active-class="none"
        @click="modalStore.closeModal()"
    >
        <img
            src="~/assets/img/medpeers-logo-light.svg"
            alt="MedPeers"
            width="152px"
            height="32px"
            loading="eager"
            fetchpriority="high"
        >
    </NuxtLink>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
const modalStore = useModalStore();
</script>

<style lang="less" src="./NavLogo.less"></style>
